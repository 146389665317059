
import { Component, Prop, Vue } from 'vue-property-decorator';
// eslint-disable-next-line import/order, import/extensions
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getView, sleep } from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';
import FuzzySearch from 'fuzzy-search';
import accountModule from '@/store/modules/accountModule';
import gptModule from '@/store/modules/gptModule';
import CustomHeader from '@/views/agGrid/aiGroupsHeader.vue';
import workflowModule from '@/store/modules/workflowModule';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    agColumnHeader1: CustomHeader,
    FormDetailPopup: () => getView('FormDetailPopup'),
    AgGridVue,
  },
})
export default class GroupList extends Vue {
  @Prop({ type: Object, required: true, default: () => {} }) group?: any;

  headers = ['', 'Group List', 'OWNER', 'CREATED', 'SUBSCRIBE', 'COPY & SHARE'];
  columns = [
    { key: 'action', editable: false },
    { key: 'query', editable: false },
    { key: 'owner', editable: true },
    { key: 'createdAt', editable: true },
    { key: 'subscriber', editable: false },
    { key: 'copyShare', editable: false },
  ];
  tableData = [
    // More rows...
  ];

  deleteGroup(row) {
    console.log('deleteGroup(row)');
    this.$emit('perform-action', { action: 'delete', group: row });
  }

  updateSubscriber(e, row) {
    console.log('updateSubscriber(row)');
    console.log(row);
    console.log(e);
    this.$emit('perform-action', { action: 'subscribe', group: row });
  }

  mergeEntries(data) {
    let result = [];
    Object.keys(data).forEach((key) => {
      result = result.concat(data[key]);
    });
    return result;
  }
  created() {
    console.log(this.group);
    console.log(this.group.filter(i => {
  if (i.settings && i.settings.is_published) {
    const isCreator = i.created_by.toLowerCase() === workflowModule.user.email.toLowerCase();
    const isSubscriber = i.settings.subscribers && i.settings.subscribers.map(email => email.toLowerCase()).includes(workflowModule.user.email.toLowerCase());
    return isCreator || isSubscriber;
  }
  return false;
}));
    // console.log( this.mergeEntries(this.group));

    this.tableData =(this.group)
      .filter((i) => {
        if (i.settings && i.settings.subscribers && i.settings.is_published) {
          return (
            workflowModule.user.email === i.created_by ||
            (i.settings.is_published)
          );
        }
        return false;
      })
      .map((g) => {
        return {
          ...g,
          _id: g._id,
          settings: g.settings,
          action: workflowModule.user.email === g.created_by,
          query: g.query,
          owner:
            workflowModule.user.email === g.created_by
              ? 'You'
              : getNameByEmail(g.created_by),
          createdAt: new Date(g.created_at).toDateString(),
          subscriber:
            g.settings &&
            g.settings.subscribers.length &&
            g.settings.subscribers.includes(workflowModule.user.email),
          copyShare: new Date(g.created_at).toDateString(),
          cgs: g.created_by,
        };
      });
  }

  searchQuery = '';

  get filterGroupList() {
    const searcher = new FuzzySearch(this.tableData, ['query', 'owner'], {
      caseSensitive: false,
    });

    let result: any = searcher.search(this.searchQuery);

    console.log(result);

    return result;
  }

  copyLink(group) {
    const url = `${window.location.origin}/Tasq-ai/${encodeURI(group._id)}`;
    navigator.clipboard.writeText(url);
  }
}
