import { render, staticRenderFns } from "./GroupsList.vue?vue&type=template&id=101ea3a3&scoped=true&"
import script from "./GroupsList.vue?vue&type=script&lang=ts&"
export * from "./GroupsList.vue?vue&type=script&lang=ts&"
import style0 from "./GroupsList.vue?vue&type=style&index=0&id=101ea3a3&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101ea3a3",
  null
  
)

export default component.exports